import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Button, Collapse } from 'antd'
import { Icon } from '@ant-design/compatible'
import { Scrollbar } from 'react-scrollbars-custom'
import { setPickedTask } from '../../../../../ducks/tasks'
import FieldEdit from './FieldEdit'
import styled from 'styled-components'
import Zoom from 'react-medium-image-zoom'
import config from '../../../../../config'

const { Panel } = Collapse

const defaultFieldsList = [
  {
    name: 'name',
    fieldType: 'text'
  },
  {
    name: 'description',
    fieldType: 'textarea'
  },
  {
    name: 'objectId',
    fieldType: 'select'
  },
  {
    name: 'status',
    fieldType: 'select'
  },
  {
    name: 'docNumber',
    fieldType: 'input',
    disabled: true
  },
  {
    name: 'checklist',
    fieldType: 'select'
  }
]

const TaskDetails = props => {
  const intl = useIntl()
  const { pickedTask } = props

  const handleCancel = () => {
    const { dispatch } = props
    dispatch(setPickedTask({}))
  }
  return (
    <div className='task-details'>
      <Scrollbar style={{ width: '100%', flex: 1 }}>
        <p className='task-title'>
          <b>{intl.formatMessage({ id: 'task details' })}</b>
          <Button htmlType='button' onClick={handleCancel}>
            <Icon type='close' />
          </Button>
        </p>
        <div className='task-content-block'>
          {defaultFieldsList.map((field, index) => (
            <FieldEdit
              key={index}
              editingField={field.name}
              fieldType={field.fieldType}
              defaultField
              disabled={field.disabled}
            />
          ))}

          {pickedTask && pickedTask.checklist && (
            <StyledCollapse
              bordered={false}
              style={{ backgroundColor: 'transparent' }}
            >
              <Panel
                header={intl.formatMessage({ id: 'checklist fields' })}
                key='1'
              >
                {pickedTask.checklist.fields.map((field, index) => (
                  <FieldEdit
                    key={index}
                    label={field.label}
                    editingField={field.key}
                    fieldType={field.type}
                  />
                ))}
              </Panel>
            </StyledCollapse>
          )}

          {pickedTask &&
            pickedTask.comment &&
            pickedTask.comment.length > 0 && (
              <FieldEdit
                editingField='comment'
                fieldType='textarea'
                defaultField
                disabled
              />
            )}

          {pickedTask.images && pickedTask.images.length > 0 && (
            <StyledCollapse
              bordered={false}
              style={{ backgroundColor: 'transparent' }}
            >
              <Panel header={intl.formatMessage({ id: 'images' })} key='2'>
                {pickedTask.images.map((value, index) => (
                  <div className='image-block' key={index}>
                    <Zoom zoomMargin={40}>
                      <img
                        src={config.server.url + value.imageThumbUrl}
                        className='img'
                        style={{ width: 100 }}
                      />
                    </Zoom>

                    <p>{value.comment}</p>
                  </div>
                ))}
              </Panel>
            </StyledCollapse>
          )}
        </div>
      </Scrollbar>
    </div>
  )
}

TaskDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pickedTask: PropTypes.object
}

const mapStateToProps = state => {
  return {
    pickedTask: state.tasks.pickedTask
  }
}

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-bottom: 0;
  }
  .ant-collapse-content-box {
    padding-left: 0;
  }
`

export default connect(mapStateToProps)(TaskDetails)
